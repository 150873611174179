<!--商城首页-->
<template>
    <div>
        <van-search
                input-align="center"
                placeholder="请输入搜索关键词"
                style="padding-bottom: 0;"
                @click="clickSearch"
        />
        <div v-for="(item,index) in configList" :key="index">
            <!--banner-->
            <van-swipe class="swipe-wrapper" :autoplay="3000" indicator-color="white" v-if="item.type == 'banner'">
                <van-swipe-item v-for="(item2,index2) in item.config" :key="index2">
                    <img class="swipe-img" :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + item2.img" @click="hrefUrlCommon(item2.url)">
                </van-swipe-item>
            </van-swipe>
            <!--            导航栏1-->
            <div class="index-nav-wrapper2" v-else-if="item.name == '专区入口矩阵11'">
                <div class="index-nav-item2" v-for="(item2,index2) in item.config" :key="index2" @click="hrefUrlCommon(item2.url)">
                    <img :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + item2.img">
<!--                    <p>{{item2.name}}</p>-->
                </div>
            </div>
            <!--导航栏-->
            <div class="index-nav-wrapper" v-else-if="item.type == 'matrix'">
                <div class="index-nav-item" v-for="(item2,index2) in item.config" :key="index2" @click="hrefUrlCommon(item2.url)">
                    <img :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + item2.img">
                    <p>{{item2.name}}</p>
                </div>
            </div>
            <!--一张横图-->
            <div v-else-if="item.type == 'images1'">
                <img v-for="(item2,index2) in item.config" :key="index2" class="img-show-large" :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + item2.img" @click="hrefUrlCommon(item2.url)">
            </div>
            <!--图片盒（3张）-->
            <div class="img-box" v-else-if="item.type == 'images2'">
                <a>
                    <img class="img-show-normal" :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + item.config[0]['img']" @click="hrefUrlCommon(item.config[0].url)">
                </a>
                <div class="img-2small-box">
                    <a><img class="img-show-small" :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + item.config[1]['img']" @click="hrefUrlCommon(item.config[1].url)"></a>
                    <a><img class="img-show-small" :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + item.config[2]['img']" @click="hrefUrlCommon(item.config[2].url)"></a>
                </div>
            </div>
            <!--1/2的商品版-->
            <div v-else-if="item.type == 'product1'">
                <product-mid-size-item v-for="(item2,index2) in item.config" :key="index2"
                                       :isSellOut="item2.is_sellout"
                                       :onlineStart="item2.online_start"
                                       :onlineEnd="item2.online_end"
                                       :orgPrice="item2.price"
                                       :price="item2.mallprice"
                                       :name="item2.name"
                                       :distributorPrice="item2.c_level1_money"
                                       :img="item2.images"
                                       :id="item2.id"
                                       :isDistributor="isDistributionMember"
                                       :cellId="item2.product_cell_id"
                ></product-mid-size-item>
            </div>
            <div v-else-if="item.type == 'product2'">
                <product-max-size-item v-for="(item2,index2) in item.config" :key="index2"
                                       :isSellOut="item2.is_sellout"
                                       :onlineStart="item2.online_start"
                                       :onlineEnd="item2.online_end"
                                       :orgPrice="item2.price"
                                       :price="item2.mallprice"
                                       :name="item2.name"
                                       :distributorPrice="item2.c_level1_money"
                                       :img="item2.index_img"
                                       :id="item2.id"
                                       :isDistributor="isDistributionMember"
                                       :cellId="item2.product_cell_id"></product-max-size-item>
            </div>
            <!--弹出层-->
            <van-popup v-model="showPop" v-else-if="item.type == 'pop' && item.config[0]" style="background-color: #f7f8fa00;" :close-on-click-overlay="false" @click-overlay="closePop()">
                <img class="pop-img" v-for="item,index in item.config" :key="index" :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + item.img" @click="hrefUrlCommon(item.url)" v-show="index === currentShowPopIndex">
            </van-popup>
        </div>
        <nav-bar></nav-bar>
    </div>
</template>

<script>
  import NavBar from '../../components/common/NavBar'
  import { getCurrentTimestamp, getStationId, hrefUrlCommon } from '../../utils/tools'
  import { globalConfig } from '../../utils/mixin'
  import ProductMidSizeItem from '../../components/product/ProductMidSizeItem'
  import { getCookie } from '../../utils/cookie'
  import { getLocalStorage, setLocalStorage } from '../../utils/localStorage'
  import ProductMaxSizeItem from '../../components/product/ProductMaxSizeItem'
  export default {
    name: 'Home',
    mixins: [globalConfig],
    components: { ProductMaxSizeItem, ProductMidSizeItem, NavBar },
    data () {
      return {
        isDistributionMember: false,
        configList: [],
        showPop: false,
        currentStation: 1,
        popData: [], // 拿到弹窗数据
        currentShowPopIndex: 0 // 当前显示哪个弹窗图片
      }
    },
    methods: {
      hrefUrlCommon,
      init: function () {
        const that = this
        this.axios.post(this.apiUrl + 'mall/init/indexV4', {
          station: this.currentStation
        }).then((response) => {
          const data = response.data
          that.configList = data.list
          // 处理一下弹窗数据
          that.configList.forEach((val) => {
            if (val.type === 'pop') {
              that.popData = val.config
            }
          })
        })
      },
      // 首屏弹窗
      firstScreenPop: function () {
        const lastPopTime = getLocalStorage('homePopTime')
        if (this.getCurrentTimestamp - lastPopTime > 3 * 60 * 60) {
          this.showPop = true
          setLocalStorage('homePopTime', this.getCurrentTimestamp)
        }
      },
      clickSearch: function () {
        this.$router.push('/search')
      },
      // 点击弹窗遮罩关闭首屏广告
      closePop: function () {
        this.currentShowPopIndex = this.currentShowPopIndex + 1
        if (this.currentShowPopIndex >= this.popData.length) {
          this.showPop = false
        }
      }
    },
    computed: {
      getCurrentTimestamp
    },
    created () {
      this.isDistributionMember = getCookie('isDistributionMember')
      // 处理弹窗
      this.firstScreenPop()
      // this.showPop = true
      // 处理站点
      this.currentStation = getStationId()
      console.log('站点：' + this.currentStation)
      // 处理不同浏览器、其中银联是需要特殊处理的
      this.init()
    }
  }
</script>

<style scoped>
    .swipe-wrapper {
        width: 95%;
        margin: 0.5rem auto 0;
    }
    .swipe-wrapper .van-swipe-item {
        width: 100%;
        color: #fff;
        font-size: 20px;
        text-align: center;
    }
    .swipe-img {
        width: 100%;
        border-radius: 1rem;
    }
    .index-nav-wrapper {
        width: 95%;
        margin: 1rem auto;
        display: flex;
        flex-flow: row;
        justify-content: space-around;
    }
    .index-nav-item {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        width: 25%;
    }
    .index-nav-item > img {
        width: 3.5rem;
        /*border-radius: 10rem;*/
    }
    .index-nav-item p {
        font-size: 0.9rem;
        font-weight: 600;
        text-align: center;
        color: #666;
    }
    .index-nav-wrapper2 {
        display: flex;
        flex-flow: row;
        justify-content: space-around;
        margin-top: 1rem;
    }
    .index-nav-item2 {
        width: 48%;
    }
    .index-nav-item2 img {
        width: 100%;
    }
    .img-show-large {
        width: 95%;
        display: block;
        margin: 0 auto;
    }
    .img-show-normal {
        width: 12.35rem;
        display: block;
        margin: 0 auto;
    }
    .img-show-small {
        width: 12.35rem;
        display: block;
        margin: 0 auto;
    }
    .img-2small-box {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }
    .img-2small-box > a:nth-child(2) {
        margin-top: 0.5rem;
    }
    .img-box {
        width: 95%;
        margin: 0 auto;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
    }
    .pop-img {
        display: block;
        width: 20rem;
        margin: 0 auto;
    }
</style>
